@charset "utf-8";
/* CSS Document */

body, h1, h2, h3, h4, h5, h6, p, a, span {
	font-family: 'Quicksand', sans-serif;
}
h1 {
	font-weight: 700;
	text-transform: uppercase;
}
.rd-navbar-default.rd-navbar-static .rd-navbar-nav > li > a:after, .rd-navbar-floated.rd-navbar-static .rd-navbar-nav > li > a:after, .rd-navbar-top-panel.rd-navbar-static .rd-navbar-nav > li > a:after {
	background: #f0f9ff; /* Old browsers */
	background: -moz-linear-gradient(left, #f0f9ff 0%, #cbebff 49%, #55bbeb 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(left, #f0f9ff 0%, #cbebff 49%, #55bbeb 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to right, #f0f9ff 0%, #cbebff 49%, #55bbeb 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
 filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f0f9ff', endColorstr='#55bbeb', GradientType=1 ); /* IE6-9 */
}
.rd-navbar-floated.rd-navbar-static .rd-navbar-nav > li > a {
	font-weight: 300;
	letter-spacing: 2px;
}
.rd-navbar-floated.rd-navbar-static .rd-navbar-nav-wrap {
	margin-top: 8px;
}
main span.lead-in {
	text-align: center;
	color: #fff;
	font-size: 20px;
	font-weight: 500;
}
main h2 {
	margin-top: 10px;
}
main h2 span.big {
	font-weight: 300;
}
main h2 span.big span {
	font-weight: 700;
}
main .swiper-slide-caption h4 {
	font-weight: 500;
	margin-top: 20px;
	position: relative;
}
main .swiper-slide-caption h4:before {
	content: url(../images/split-arrow.png);
	position: absolute;
	left: 50%;
	margin-left: -30px;
	bottom: -80px;
}
main .portal {
	padding: 20px 10px;
	background: rgba(0,0,0,.3);
	border: 1px solid #fff;
	border-radius: 3px;
}
main .portal h5 {
	font-weight: 300;
}
main .portal h5 span {
	font-weight: 700;
}
.btn-primary {
	color: #fff;
	background-color: #fa7700;
	border-color: #b85800;
}
.btn-blue {
	color: #fff;
	background-color: #55bbeb;
	border-color: #2191c6;
}
.bg-blue-white {
	background: #55bbeb;
}
section.lead-in {
	background: url(../images/lead-in.jpg) no-repeat center;
	background-size: cover;
	background-attachment: fixed;
}
.lead-in h3 {
	font-weight: 700;
	font-size: 54px;
}
.lead-in ul {
	font-size: 24px;
	text-transform: uppercase;
	font-weight: 700;
}
.lead-in ul li {
	margin: 20px 0;
}
.lead-in ul li i {
	color: #fa7700;
}
.icon-lg.icon-rect, .icon-lg.icon-rounded, .icon-lg.icon-circle, .icon-lg.icon-outlined {
	width: 120px;
	height: 120px;
	line-height: 120px;
	font-size: 56px;
	border-color: #55bbeb;
}
#technology .icon-circle {
	border: 2px solid #55bbeb;
	transition: .3s;
	-webkit-transition: .3s;
	-webkit-box-shadow: 0px 0px 50px 0px rgba(0,0,0,0.15);
	-moz-box-shadow: 0px 0px 50px 0px rgba(0,0,0,0.15);
	box-shadow: 0px 0px 50px 0px rgba(0,0,0,0.15);
	background: rgba(255,255,255,1);
	background: -moz-linear-gradient(-45deg, rgba(255,255,255,1) 0%, rgba(246,246,246,1) 47%, rgba(237,237,237,1) 100%);
	background: -webkit-gradient(left top, right bottom, color-stop(0%, rgba(255,255,255,1)), color-stop(47%, rgba(246,246,246,1)), color-stop(100%, rgba(237,237,237,1)));
	background: -webkit-linear-gradient(-45deg, rgba(255,255,255,1) 0%, rgba(246,246,246,1) 47%, rgba(237,237,237,1) 100%);
	background: -o-linear-gradient(-45deg, rgba(255,255,255,1) 0%, rgba(246,246,246,1) 47%, rgba(237,237,237,1) 100%);
	background: -ms-linear-gradient(-45deg, rgba(255,255,255,1) 0%, rgba(246,246,246,1) 47%, rgba(237,237,237,1) 100%);
	background: linear-gradient(135deg, rgba(255,255,255,1) 0%, rgba(246,246,246,1) 47%, rgba(237,237,237,1) 100%);
 filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ededed', GradientType=1 );
}
.technology-section-content h6 {
	text-transform: uppercase;
	font-weight: 700;
}
#technology .technology-section-content:hover .icon-circle {
	background-color: #fa7700;
	color: #fff;
	border-color: #b85800!important;
	background: rgba(255,175,75,1);
	background: -moz-linear-gradient(-45deg, rgba(255,175,75,1) 0%, rgba(255,146,10,1) 100%);
	background: -webkit-gradient(left top, right bottom, color-stop(0%, rgba(255,175,75,1)), color-stop(100%, rgba(255,146,10,1)));
	background: -webkit-linear-gradient(-45deg, rgba(255,175,75,1) 0%, rgba(255,146,10,1) 100%);
	background: -o-linear-gradient(-45deg, rgba(255,175,75,1) 0%, rgba(255,146,10,1) 100%);
	background: -ms-linear-gradient(-45deg, rgba(255,175,75,1) 0%, rgba(255,146,10,1) 100%);
	background: linear-gradient(135deg, rgba(255,175,75,1) 0%, rgba(255,146,10,1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffaf4b', endColorstr='#ff920a', GradientType=1 );
	width: 150px;
	height: 150px;
	line-height: 150px;
	font-size: 64px;
}
.technology-section-content {
	transition: .3s;
	-webkit-transition: .3s;
}
.technology-section-content:hover {
	margin-bottom: -15px;
	margin-top: 26px!important;
}
section#technology, section.why {
	background: url(../images/tech-bg.png) center no-repeat;
	background-size: cover;
	background-attachment: fixed;
	border-top: #ccc solid 2px;
	border-bottom: #ccc solid 2px;
}
.text-subline:before {
	height: 4px;
	top: -3px;
	background: #55bbeb;
}
.thumbnail-terry-title, .thumbnail-terry-desc {
	white-space: normal;
	text-overflow: clip;
}
h3.thumbnail-terry-title {
	font-weight: 700;
	text-transform: uppercase;
}
@media (max-width: 768px) {
	#check {
		text-align: left;
	}
	.lead-in h3 {
		font-size: 38px;
	}
	.lead-in ul {
		font-size: 20px;
	}
}