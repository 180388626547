.logo_left {
    float: none;
}

section.bg-mytech {
    background: url(../images/me-tech.jpg) center no-repeat fixed;
    background-size: cover;
}

section.bg-mytech .mytechwrap {
    background: rgba(1, 2, 11, .6);
}

.white-font {
    color: #fff !important;
}


/*utility*/

.noPadding {
    padding: 0px !important;
}

.noMargin {
    margin: 0px !important;
}

.padding-bottom10 {
    padding-bottom: 10px !important;
}

.padding-bottom20 {
    padding-bottom: 40px !important;
}

.padding-bottom30 {
    padding-bottom: 30px !important;
}

.padding-TB50 {
    padding-top: 50px!important;
    padding-bottom: 50px!important;
}

.margin-bottom10 {
    margin-bottom: 10px !important;
}

.margin-bottom20 {
    margin-bottom: 20px !important;
}

.margin-bottom30 {
    margin-bottom: 30px !important;
}

.margin-bottom40 {
    margin-bottom: 40px !important;
}

.margin-bottom50 {
    margin-bottom: 50px !important;
}

.margin-top10 {
    margin-top: 10px !important;
}

.margin-top20 {
    margin-top: 20px !important;
}

.margin-top30 {
    margin-top: 30px !important;
}

.margin-top40 {
    margin-top: 40px !important;
}

.margin-top50 {
    margin-top: 50px !important;
}

.middle-left {
    justify-content: left;
    align-items: center;
    display: flex;
}

.serviceWrap {
    position: absolute;
    z-index: 99;
    bottom: 130px;
    margin-left: -600px;
    left: 50%;
}

.serviceWrap p {
    padding: 0 20px;
    margin-bottom: 50px;
    font-size: 14px;
    color: #000;
}

.serviceWrap .portal {
    background: #fff;
    width: 94%;
    margin: 0 auto;
    padding: 0px;
    box-shadow: 0px 5px 21px #888;
}

.serviceWrap .serviceImg {
    border: 2px solid #ccc;
    border-bottom: 0px;
    position: relative;
}

.serviceWrap .serviceImg img {
    width: 100%
}

.serviceWrap .serviceImg span {
    position: absolute;
    bottom: 0;
    left: 0;
    color: #fff;
    font-size: 18px;
    text-transform: uppercase;
    background: rgba(0, 0, 0, 0.6);
    padding: 10px;
    text-align: center;
    width: 100%
}

.serviceWrap a {
    position: absolute;
    bottom: -24px;
    left: 50%;
    margin-left: -85px;
}

.swiper-slide-caption {
    padding: 0 15px;
    margin-top: -233px !important;
}

.section-sm-88 {
    padding-top: 200px;
    padding-bottom: 66px;
}

html .page .offset-md-top-50 {
    margin-top: 10px !important;
}

.techWorkwrap {
    width: 100%;
    padding: 15px;
    border: 4px solid transparent;
}

.techWorkwrap .techWork {
    background: #fff;
    margin: 0 auto;
    padding: 0px;
    padding-bottom: 20px;
    border-bottom: 5px solid #c3e2f1;
    text-align: left;
}

.techWorkwrap .techWork .serviceText {
    padding: 0 20px;
    margin-bottom: 0px;
    font-size: 14px;
    color: #000;
}

.techWorkwrap .techWork .serviceText p {
    padding-top: 20px;
    margin-bottom: 0px;
    font-size: 14px;
    color: #000;
}

.techWorkwrap .techWork .serviceImg {
    position: relative;
}

.techWorkwrap .techWork .serviceImg img {
    width: 100%;
}

.techWorkwrap .techWork .serviceImg span {
    position: absolute;
    bottom: 0;
    left: 0;
    color: #fff;
    font-size: 18px;
    text-transform: uppercase;
    background: rgba(0, 0, 0, 0.6);
    padding: 10px;
    text-align: center;
    width: 100%
}

.techWorkwrap:hover {
    border: 4px solid #fa7700;
    padding: 15px;
}

.techWorkwrap:hover .techWork {
    box-shadow: 0px 5px 21px #888;
    border-bottom: 5px solid #fff;
}

.techWorkwrap:hover .techWork .btn.btn-blue {
    color: #fff;
    background-color: #fa7700;
    border-color: #b85800;
}

.thumbnail-terry a.hireBt {
    position: absolute;
    z-index: 99;
    bottom: 0px;
    background: rgba(3, 3, 3, 0.6);
    width: 100%;
    left: 0;
    padding: 10px;
    font-size: 16px;
    color: #fff;
    text-transform: uppercase;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    opacity: 1;
    -webkit-transform: none;
    transform: none;
    border: 0px;
    pointer-events: none;
}

.thumbnail-terry-desc {
    font-size: 14px;
    line-height: 20px;
}

.thumbnail-terry a.hireBt .Hshow {
    display: none;
}

.thumbnail-terry:after {
    background: rgba(0, 0, 0, 0.6);
}

.thumbnail-terry figcaption {
    bottom: -80px;
    padding: 11px 15px;
}

.thumbnail-terry a.hireBt:hover .Hshow {
    display: block;
    display: none;
}

.thumbnail-terry a.hireBt:hover .Dshow {
    display: none;
    display: block;
}

.thumbnail-terry:hover a.hireBt .Hshow {
    display: block;
    display: none;
}

.thumbnail-terry:hover a.hireBt .Dshow {
    display: none;
    display: block;
}


/*.thumbnail-terry:hover .Dshow{display: none;}*/

.thumbnail-terry:hover figcaption {
    bottom: 50%;
    margin-bottom: -30px;
}

.thumbnail-terry:hover a.hireBt {
    content: "";
    background: rgb(250, 119, 0);
}

.thumbnail-terry:hover {
    background: rgba(0, 0, 0, 1);
    border-radius: 5px;
}

.thumbnail-terry:hover:before {
    opacity: .6;
}

.swiper-slide-active:after {
    background: rgba(25, 25, 25, .8);
}

.rightArrow {
    margin: 40px 27px;
    padding: 0px;
}

.rightArrow li {
    margin: 12px 0;
    padding: 0 29px;
    background: url(../images/rightArrow.png) 0px 2px no-repeat;
    display: block;
}

.dividerWith {
    width: 6px;
}

h3.title {
    font-size: 30px;
    font-weight: bold;
    color: #fa7700 !important;
    text-transform: none;
}

section#technology,
section.why {
    background: url(../images/tech-bg.png) center no-repeat fixed;
    background-size: cover;
    border-top: #ccc solid 0px;
    border-bottom: #ccc solid 0px;
}

.text-subline:before {
    background: #fa7700;
}

.contentP p {
    margin: 5px;
}

.contactMain {
    box-shadow: 0px 0px 32px #c4c3c3;
    padding: 30px 20px;
    width: 1200px;
    position: absolute;
    background: #fff;
    z-index: 9;
    left: 50%;
    margin-left: -600px;
}

.vkmap {
    margin-top: 500px;
}

.bg-white {
    background: #fff;
}

.innerbanner {
    margin-top: 56px !important;
    height: 150px;
    justify-content: center;
    align-items: center;
    display: flex;
}

.innerbanner h2 {
    font-size: 50px;
    line-height: 50px;
    color: #fff;
    font-weight: bold;
    width: 80%;
}

.gray_bg {
    background: #f5f5f5
}

.fullwidth {
    width: 100%
}

.md-margin-top50 {
    margin-top: 0!important;
}

.btn-blue:hover {
    color: #fff;
    background-color: #fa7700;
    border-color: #b85800;
}

.justifyText {
    text-align: justify;
}

.alogin {
    border: 2px solid #f7f2f2;
    padding: 0 8px;
    background: #fa7700;
    color: #fff;
    font-weight: bold !important;
}


/*start css by bhautik*/

.icon,
.icon-outlined,
.icon:before {
    /*  position: relative;*/
    color: #fabc3d;
}

.afterlogin-header .top-username {
    text-transform: capitalize;
    padding: 0 20px;
    border-left: 1px solid #fabd3d;
    box-shadow: -1px 0 0 #fabd3d;
}


/*.afterlogin-header .rd-navbar-nav-wrap {
    background: #2c329c!important;
}*/

.afterlogin-header .rd-navbar-nav a:hover,
.afterlogin-header li.rd-navbar-submenu a {
    background: #2c329c!important;
}

.afterlogin-header ul.rd-navbar-dropdown a {
    background: #6569be!important;
}

.afterlogin-header .rd-navbar-dropdown li a {
    border-left: 5px solid #2c329c;
}

.section-divider {
    border-bottom: 1px solid #b7b7b7;
    box-shadow: 0 1px 0 #444b55;
    padding: 10px 15px;
    padding-top: 0px;
}


/*.afterlogin-header .bottom-divider {
    border-bottom: 1px solid #fabd3d;
}*/

.afterlogin-header ul.rd-navbar-nav li {
    border-bottom: 1px solid #fabd3d;
    font-size: 14px;
}

.bg-userprofile {
    font-weight: 600 !important;
}


/*.section-divider h6 {
    letter-spacing: 1px;
    font-weight: 100;
    font-size: 18px;
}*/

.profile-container,
.workorder-container {
    border: 1px solid #fbfbfb;
    padding-top: 30px;
    padding-bottom: 30px;
    background: #fbfbfb;
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 1px 8px 0 rgba(0, 0, 0, 0.12), 0 3px 3px -2px rgba(0, 0, 0, 0.4);
}

.afterlogin-header ul.rd-navbar-nav li a {
    padding-top: 10px!important;
    padding-bottom: 10px!important;
    line-height: 32px!important;
    font-size: 14px !important;
}

.mdi-checkbox-marked-circle:before {
    content: "\f229";
    color: #5157b8;
}

h1,
h4 {
    color: #4a50ae;
}

.text-subline {
    height: 2px;
    margin: 12px 0!important;
    background: #ececec !important;
    margin-bottom: 15px !important;
}

.text-subline:before {
    width: 70px;
}

.technician-profile-view-wrapper .text-subline:before {
    height: 10px;
    background: #fabd3d;
}

.message-notify-btn>a.active,
.message-notify-btn>a:hover {
    /*   background: linear-gradient(90deg, #575cbf 0%, #3a3e96 100%); */
    background: linear-gradient(90deg, #2c329c 0%, #2c329c 100%);
    padding: 13px 20px;
}

.message-notify-btn>a {
    display: block;
    padding: 13px 20px;
}

.notification-btn>a {
    padding: 13px 20px;
}

.notification-btn>a.active,
.notification-btn>a:hover {
    /*background: linear-gradient(90deg, #575cbf 0%, #3a3e96 100%); */
    background: linear-gradient(90deg, #2c329c 0%, #2c329c 100%);
    padding: 13px 20px;
}

.notification-wrapper {
    /*   background: linear-gradient(90deg, #575cbf 0%, #3a3e96 100%); */
    background: linear-gradient(90deg, #1b207a 0%, #1b207a 100%);
}

.notification-wrapper ul li {
    border-bottom: 1px solid #f5c030;
}

.notification-dropdown-btn {
    border-top: 1px solid #fabd3d;
}

.notification-wrapper ul li.unread,
.notification-wrapper ul li:hover {
    background: #464aa7;
}

.btn-darkest,
.page .text-dark,
.page blockquote.quote .text-dark {
    /* color: #6b6b6b;*/
}

.fa-stack,
.page .text-middle,
.thumbnail-terry figcaption,
.thumbnail-zoe .list-inline li {
    vertical-align: middle;
    font-size: 25px;
    color: #6569be !important;
}

.order-content-footer .list-inline-sm li .text-dark {
    color: #333 !important;
    font-size: 15px;
}

.icon-xs {
    font-size: 25px;
    line-height: 37px;
}

.order-content {
    letter-spacing: .5px;
    font-size: 15px;
    color: #333;
}

.order-section {
    padding: 15px 15px;
    border-bottom: 1px solid #e3e7f3;
}

.order-section h4 {
    font-size: 18px;
    letter-spacing: .5px;
    margin: 5px 0 0;
}

.btn-anis-effect.btn-primary {
    color: #ffffff;
    background-color: #fabd3d;
    border-color: #fbbe3d;
}

.white-box label {
    font-size: 14px;
    color: #1d2025;
    margin-bottom: 10px;
    letter-spacing: .5px;
}

.notification-btn>a>.badge {
    position: absolute;
    top: 10px;
    right: 16px;
    padding: 4px 5px;
    font-size: 9px;
    border: 1px solid #42b574;
    width: 34px;
    height: 19px;
    /* position: absolute;
    top: 10px;
    right: 18px;
    padding: 4px 5px;
    font-size: 9px;
    border: 1px solid #42b574;
    width: 20px;
    height: 20px; */
}

.afterlogin-header .rd-navbar-submenu-toggle::before {
    line-height: 55px!important;
    color: #fabd3d;
}

.rd-navbar-floated.rd-navbar-dark.rd-navbar-fixed .rd-navbar-nav li.active>a {
    background: #6569be !important;
    color: #fabd3d;
}

.profile-container,
.workorder-container {
    border: 1px solid #fbfbfb;
    padding-top: 30px;
    padding-bottom: 30px;
    /*background: #fbfbfb;*/
    background: white;
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 1px 8px 0 rgba(0, 0, 0, 0.12), 0 3px 3px -2px rgba(0, 0, 0, 0.4) !important;
}

.section-divider h6 {
    letter-spacing: 1px;
    font-weight: 100;
    font-size: 16px;
}

.username {
    float: left;
    width: 180px;
    vertical-align: top;
    text-align: left;
    margin: 0 0 0 10px;
    letter-spacing: .5px;
    /*color: #6b6b6b;*/
    color: #ffffff;
}

.afterlogin-header .rd-navbar-dropdown li.active a,
.afterlogin-header .rd-navbar-dropdown li:hover a {
    border-color: #ff7900!important;
    background: #fbbe3d8a !important;
}

.rd-navbar-floated.rd-navbar-dark.rd-navbar-fixed .rd-navbar-nav li>a:hover {
    background: #6569be !important;
    color: #fabd3d;
}

.page,
body {
    background-color: #ECECEC !important;
}


/* for white header*/

.afterlogin-header .top-right-header {
    position: fixed;
    top: 0;
    right: 15px;
    z-index: 16;
    color: #2c329c;
}

.fa-stack,
.page .text-middle,
.thumbnail-terry figcaption,
.thumbnail-zoe .list-inline li {
    vertical-align: middle;
    font-size: 25px;
    color: #6569be !important;
}

.order-content-footer .list-inline-sm li .text-dark {
    color: #333 !important;
    font-size: 14px;
}

.order-content {
    letter-spacing: .5px;
    font-size: 15px;
    color: #333;
}

.order-section {
    padding: 10px 15px;
    border-bottom: 1px solid #e3e7f3;
}

.order-section h4 {
    font-size: 16px;
    letter-spacing: .5px;
    margin: 5px 0 0;
}

.btn-anis-effect.btn-primary {
    color: #ffffff;
    background-color: #fabd3d;
    border-color: #fbbe3d;
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 1px 8px 0 rgba(0, 0, 0, 0.12), 0 3px 3px -2px rgba(0, 0, 0, 0.4) !important;
}

.btn-anis-effect.btn-primary:hover {
    color: #fff;
    border-color: #4448a4;
    background: #4246a2;
}

.btn-anis-effect.btn-primary:after,
.btn-anis-effect.btn-primary:before {
    background: #4549a6;
}

.white-box label {
    font-size: 14px;
    color: #1d2025;
    margin-bottom: 10px;
    letter-spacing: .5px;
}

.notification-btn>a>.badge {
    /* position: absolute;
    top: 10px;
    right: 18px;
    padding: 4px 5px;
    font-size: 9px;
    border: 1px solid #42b574;
    width: 20px;
    height: 20px; */
    position: absolute;
    top: 10px;
    right: 16px;
    padding: 4px 5px;
    font-size: 9px;
    border: 1px solid #42b574;
    width: 34px;
    height: 19px;
}


/*end css for white header*/


/*menu color 3*/

.rd-navbar-brand,
.rd-navbar-panel {
    /*  background-image: linear-gradient(90deg, #575cbf 0%, #3a3e96 100%) !important;*/
    background: #1b207a !important;
}


/*.afterlogin-header .bottom-divider {
    border-bottom: 1px solid #fabd3d;
    background: #696969;
}*/

.afterlogin-header .bottom-divider {
    border-bottom: 1px solid #fabd3d;
    /*background: #fbfbfb;*/
    background: #2c329c;
}

.user-content .user-name {
    font-size: 14px;
    color: #fabd3d;
}

.afterlogin-header .logo-wrapper {
    padding: 11px 10px;
    /* background: white;*/
    background: #1c207a;
}

.select2-container--bootstrap .select2-results__option--highlighted[aria-selected] {
    background-color: #4145a0;
    color: #fff;
}

.afterlogin-header .logout-btn a {
    display: block;
    font-size: 14px;
    letter-spacing: 1px;
}

h1,
h4 {
    color: #505050;
}

.afterlogin-header .top-right-header {
    position: fixed;
    top: 0;
    right: 15px;
    z-index: 16;
    color: white;
    font-size: 14px;
}

.afterlogin-header .logo-wrapper a img {
    width: 100%;
}

.afterlogin-header .rd-navbar-nav-wrap {
    /*background: #6569be !important;*/
    background: #1b207a !important;
}

.afterlogin-header .rd-navbar-nav a:hover,
.afterlogin-header li.rd-navbar-submenu a {
    background: #2c329c!important;
}

.afterlogin-header ul.rd-navbar-nav li a {
    padding-top: 10px!important;
    padding-bottom: 10px!important;
    line-height: 32px!important;
    background: #2c329c;
    font-size: 14px !important;
}

.fa-stack,
.page .text-middle,
.thumbnail-terry figcaption,
.thumbnail-zoe .list-inline li {
    vertical-align: middle;
    font-size: 25px;
    color: #6569be !important;
}

.form-control {
    height: 40px;
}

.rd-navbar-nav li a i {
    font-size: 18px;
    vertical-align: middle;
    padding-right: 5px;
    width: 23px;
    text-align: center;
}

.icon-xxs.icon-circle,
.icon-xxs.icon-outlined,
.icon-xxs.icon-rect,
.icon-xxs.icon-rounded {
    width: 35px;
    height: 35px;
    line-height: 35px;
}

.order-section-right>label:hover .mdi {
    background-color: #4145a0;
    color: #fff;
}

.btn-anis-effect.btn-default,
.pagination-classic>li+li:not(:last-child)>a.btn-anis-effect,
.pagination-classic>li+li:not(:last-child)>span.btn-anis-effect {
    color: #434345;
    background-color: transparent;
    border-color: #d9d9d9;
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 1px 8px 0 rgba(0, 0, 0, 0.12), 0 3px 3px -2px rgba(0, 0, 0, 0.4) !important;
}

.page .text-bold,
.page strong {
    font-weight: 100;
    font-size: 14px;
}

.technician-profile-text {
    margin: 0!important;
    color: grey;
    font-size: 14px;
}

.label.label-primary.skill {
    background-color: #4347a3;
}

.photo-wrapper .user-type {
    margin: 0;
    font-size: 16px;
}

.technician-name {
    font-size: 30px;
}

.btn.btn-icon.btn-block.btn-primary.offset-top-20 {
    color: #ffffff;
    background-color: #fabd3d;
    border-color: #fbbe3d;
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 1px 8px 0 rgba(0, 0, 0, 0.12), 0 3px 3px -2px rgba(0, 0, 0, 0.4) !important;
}

.btn.btn-icon.btn-block.btn-primary.offset-top-20:hover {
    color: #fff;
    border-color: #4448a4;
    background: #4246a2;
}

.icon.mdi.mdi-arrow-right-bold-circle-outline:before {
    color: white;
}

html .page .offset-top-24.bg-changepass {
    margin-top: 40px;
    font-size: 16px;
    padding-bottom: 0;
}

.page .text-middle.bg-text-middle {
    font-size: 14px;
    margin-top: -10px;
    color: #434345 !important;
}

.text-extra-small.text-dark.offset-top-4 {
    font-size: 12px;
}

.text-left.offset-top-30.bv-form {
    margin-top: 20px;
}

.text-center.bg-p-text-center {
    margin-bottom: 10px;
}

.select2-container--bootstrap .select2-selection--single {
    text-align: left;
    height: 40px;
    line-height: 20px;
    padding: 9px 28px 9px 16px;
}

.message-notify-list-wrapper.active {
    opacity: 1;
    z-index: 1;
    display: block;
    transition: .3s all ease;
    /*   background: linear-gradient(90deg, #575cbf 0%, #3a3e96 100%); */
    background: linear-gradient(90deg, #1b207a 0%, #1b207a 100%);
}

.afterlogin-header .rd-navbar-nav>li>ul>li>a i {
    font-size: 14px!important;
    vertical-align: baseline;
}

label {
    margin-bottom: 10px;
    font-weight: 400;
    font-size: 14px;
    color: #1d2025;
    margin-bottom: 10px;
    letter-spacing: .5px;
}

.error {
    color: red;
    font-size: 12px;
}

.select2-container--bootstrap .select2-selection--multiple {
    min-height: 40px;
    max-height: 40px;
    overflow-y: auto;
}

.select2-container--bootstrap .select2-selection--multiple .select2-selection__choice {
    color: #212121;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 3px;
    cursor: default;
    float: left;
    margin: 4px 0 0 10px;
    padding: 0 9px;
}

div.dataTables_scrollHead table.table-bordered,
table.table-bordered.dataTable tbody td,
table.table-bordered.dataTable tbody th {
    border-bottom-width: 0;
    font-size: 14px;
    vertical-align: middle;
}

.shadow {
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 1px 8px 0 rgba(0, 0, 0, 0.12), 0 3px 3px -2px rgba(0, 0, 0, 0.4) !important;
}

.NewData div p {
    padding: 10px!important;
    border-bottom: 1px solid #ececec;
    margin: 0;
    font-size: 14px;
}

.NewData div p div {
    margin-right: 10px;
    vertical-align: middle;
}

.technician-list-header .technician-photo-section img {
    width: 90px;
    height: 90px;
}

.technician-list-header .technician-data-section h5 {
    font-size: 16px;
    font-weight: bold;
}

.technician-list-header .technician-data-section ul li .text-middle {
    vertical-align: middle;
    font-size: 14px;
    color: #6569be !important;
    line-height: 24px;
}

.technician-btn-section>label:hover .mdi {
    background-color: #4448a5;
    color: #fff;
    border: 1px solid #4448a5;
}

.datatable-btn label:hover .mdi {
    background-color: #4448a5;
    color: #fff;
    border: 1px solid #4448a5;
}

.table>tbody>tr>td,
.table>tbody>tr>th,
.table>tfoot>tr>td,
.table>tfoot>tr>th,
.table>thead>tr>td,
.table>thead>tr>th {
    padding: 8px;
    line-height: 1.5;
    vertical-align: top;
    border-top: 1px solid #eaeaea;
    font-size: 14px;
}

div#cpf2d99ada57 {
    width: 150px !important;
    margin-right: 15px !important;
}

div#cpf2d99ada57 * {
    background-color: #ff9900 !important;
}

div#cpf2d99ada57 .r-fg {
    background-color: #ff9900 !important;
    border-color: #ff9900 !important;
}

div#cpf2d99ada57 div.cpwrap {
    border-left: 3px solid #ff9900 !important;
    border-right: 3px solid #ff9900 !important;
}

.afterlogin-header .rd-navbar-nav>li>ul>li>a {
    font-size: 13px!important;
}

.footer-powered-by {
    text-align: center;
}

.bg-lightest {
    background: white;
    fill: white;
}

.tb-mg-logo {
    display: none;
}

.desktop-logo {
    display: block;
}

.notification-dropdown-btn>.btn {
    margin-top: 0 !important;
    font-size: 11px;
    font-weight: 800;
    background: #fabc3d;
    color: #2c329c;
}


/*.notification-wrapper ul::-webkit-scrollbar {
    width: 1em;
}

.notification-wrapper ul::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}

.notification-wrapper ul::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
}*/


/*end menu color 3*/

a,
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
    font-family: "Open Sans", sans-serif;
}


/*end css by bhautik*/


/*start css for new notification*/

.timeline-centered-bg {
    position: relative;
    margin-bottom: 30px;
}

.timeline-centered-bg:before,
.timeline-centered-bg:after {
    content: " ";
    display: table;
}

.timeline-centered-bg:after {
    clear: both;
}

.timeline-centered-bg:before,
.timeline-centered-bg:after {
    content: " ";
    display: table;
}

.timeline-centered-bg:after {
    clear: both;
}

.timeline-centered-bg:before {
    content: '';
    position: absolute;
    display: block;
    width: 4px;
    background: #f5f5f6;
    left: 3%;
    top: 20px;
    bottom: 20px;
    margin-left: -4px;
}

.timeline-centered-bg .timeline-entry {
    position: relative;
    width: 97%;
    float: right;
    margin-bottom: 15px;
    clear: both;
}

.timeline-centered-bg .timeline-entry:before,
.timeline-centered-bg .timeline-entry:after {
    content: " ";
    display: table;
}

.timeline-centered-bg .timeline-entry:after {
    clear: both;
}

.timeline-centered-bg .timeline-entry:before,
.timeline-centered-bg .timeline-entry:after {
    content: " ";
    display: table;
}

.timeline-centered-bg .timeline-entry:after {
    clear: both;
}

.timeline-centered-bg .timeline-entry.begin {
    margin-bottom: 0;
}

.timeline-centered-bg .timeline-entry .timeline-entry-inner {
    position: relative;
    margin-left: -22px;
}

.timeline-centered-bg .timeline-entry .timeline-entry-inner:before,
.timeline-centered-bg .timeline-entry .timeline-entry-inner:after {
    content: " ";
    display: table;
}

.timeline-centered-bg .timeline-entry .timeline-entry-inner:after {
    clear: both;
}

.timeline-centered-bg .timeline-entry .timeline-entry-inner:before,
.timeline-centered-bg .timeline-entry .timeline-entry-inner:after {
    content: " ";
    display: table;
}

.timeline-centered-bg .timeline-entry .timeline-entry-inner:after {
    clear: both;
}

.timeline-centered-bg .timeline-entry .timeline-entry-inner .timeline-time {
    position: absolute;
    left: -100px;
    text-align: right;
    padding: 10px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.timeline-centered-bg .timeline-entry .timeline-entry-inner .timeline-time>span {
    display: block;
}

.timeline-centered-bg .timeline-entry .timeline-entry-inner .timeline-time>span:first-child {
    font-size: 15px;
    font-weight: bold;
}

.timeline-centered-bg .timeline-entry .timeline-entry-inner .timeline-time>span:last-child {
    font-size: 12px;
}

.timeline-centered-bg .timeline-entry .timeline-entry-inner .timeline-icon {
    background: #fff;
    color: #737881;
    display: block;
    width: 40px;
    height: 40px;
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
    text-align: center;
    -moz-box-shadow: 0 0 0 5px #f5f5f6;
    -webkit-box-shadow: 0 0 0 5px #f5f5f6;
    box-shadow: 0 0 0 5px #f5f5f6;
    line-height: 40px;
    font-size: 15px;
    float: left;
}

.timeline-centered-bg .timeline-entry .timeline-entry-inner .timeline-icon.bg-success {
    background-color: #fabd3d;
    ;
    color: #fff;
}

.timeline-centered-bg .timeline-entry .timeline-entry-inner .timeline-label {
    position: relative;
    background: #f5f5f6;
    padding: 1em;
    margin-left: 70px;
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
}

.timeline-centered-bg .timeline-entry .timeline-entry-inner .timeline-label:after {
    content: '';
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 9px 9px 9px 0;
    /*border-color: transparent #f5f5f6 transparent transparent;*/
    border-color: transparent #ececec transparent transparent;
    left: 0;
    top: 10px;
    margin-left: -9px;
}

.timeline-centered-bg .timeline-entry .timeline-entry-inner .timeline-label h2,
.timeline-centered-bg .timeline-entry .timeline-entry-inner .timeline-label p {
    color: #737881;
    font-family: "Noto Sans", sans-serif;
    font-size: 12px;
    margin: 0;
    line-height: 1.428571429;
}

.timeline-centered-bg .timeline-entry .timeline-entry-inner .timeline-label p+p {
    margin-top: 15px;
}

.timeline-centered-bg .timeline-entry .timeline-entry-inner .timeline-label h2 {
    font-size: 16px;
    margin-bottom: 10px;
}

.timeline-centered-bg .timeline-entry .timeline-entry-inner .timeline-label h2 a {
    color: #303641;
}

.timeline-centered-bg .timeline-entry .timeline-entry-inner .timeline-label h2 span {
    -webkit-opacity: .6;
    -moz-opacity: .6;
    opacity: .6;
    -ms-filter: alpha(opacity=60);
    filter: alpha(opacity=60);
}

.timeline-label h4 {
    line-height: 1.25;
    font-size: 16px;
}

.timeline-label ul li a {
    font-size: 14px;
}


/*end css for new notification*/

@media screen and (max-width: 1199px) {
    .serviceWrap {
        margin-left: -486px !important;
    }
    .techWorkwrap .techWork .serviceImg span {
        font-size: 12px;
    }
    .contactMain {
        width: 900px;
        margin-left: -450px;
    }
    .smMarginTop30 {
        margin-top: 30px;
    }
    .smMarginTop40 {
        margin-top: 40px;
    }
}

@media screen and (max-width: 992px) {
    .serviceWrap {
        margin-left: -374px !important;
    }
    .reveal-sm-block {
        display: block !important;
    }
    .contactMain {
        width: 700px;
        margin-left: -350px;
    }
    .thumbnail-terry,
    .thumbnail-terry>figure {
        overflow: hidden;
    }
    .thumbnail-terry:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        border-radius: 5px;
        background: rgba(0, 0, 0, .3);
        background: -moz-linear-gradient(top, transparent 0, rgba(0, 0, 0, .5) 100%);
        background: -webkit-linear-gradient(top, transparent 0, rgba(0, 0, 0, .5) 100%);
        background: linear-gradient(to bottom, transparent 0, rgba(0, 0, 0, .5) 100%);
        filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#000000', endColorstr='#000000', GradientType=0);
        will-change: transform;
    }
    .thumbnail-terry-desc,
    .thumbnail-terry:before {
        display: block;
    }
    .thumbnail-terry figcaption {
        position: absolute;
        top: auto;
        bottom: 0;
        left: 0;
        z-index: 2;
        padding: 30px 15px;
        background: 0 0;
        -webkit-transform: translateY(50px);
        transform: translateY(50px);
    }
    .thumbnail-terry:hover figcaption {
        bottom: 50%;
        margin-bottom: 14px;
    }
    .thumbnail-terry figcaption {
        bottom: -44px;
        padding: 11px 15px;
    }
}

@media screen and (max-width: 767px) {
    /*utility small divces*/
    .xsMarginTop10 {
        margin-top: 10px;
    }
    .xsMarginTop20 {
        margin-top: 20px;
    }
    .xsMarginTop30 {
        margin-top: 30px;
    }
    .xsMarginTop40 {
        margin-top: 40px;
    }
    .serviceWrap {
        margin-left: -241px !important;
        top: 42px;
        position: relative;
        left: 50%;
    }
    .swiper-slide-caption {
        margin-top: 0 !important;
    }
    .serviceWrap a {
        position: relative;
        bottom: 0;
        left: 0;
        margin-left: 0;
        margin-top: -55px;
    }
    .section-sm-88 {
        padding-top: 77px;
        padding-bottom: 66px;
    }
    .serviceWrap .portal {
        width: 80%;
    }
    .contactMain {
        position: relative;
        width: 90%;
        margin-left: -44%;
        margin-top: 50px;
    }
    .vkmap {
        margin-top: -43px;
    }
    .dividerWith {
        width: 100%;
    }
    h3.title {
        text-align: center;
    }
    .form-label.focus {
        top: -20px;
        font-size: 16px;
        left: 0px;
        color: #000000;
    }
    .md-margin-top50 {
        margin-top: 50px !important;
    }
    .innerbanner h2 {
        font-size: 24px;
        line-height: 30px;
    }
    .managed .middle-left {
        display: block!important;
    }
}

@media screen and (max-width: 639px) {}

@media screen and (max-width: 360px) {
    .serviceWrap .portal {
        width: 70%;
    }
    .section-sm-88 {
        padding-top: 40px;
    }
}

.carousel-showmanymoveone .carousel-control {
    width: 2%;
    background-image: none;
    color: #fa7700;
}

.carousel-indicators {
    bottom: -65px;
}

.carousel-indicators .active {
    background: #fa7700;
}

.carousel-indicators li {
    background: #e2e2e2;
    border-color: #e2e2e2;
}


/*
.carousel-showmanymoveone .carousel-control.left {
   margin-left: 0;
}

.carousel-showmanymoveone .carousel-control.right {
   margin-right: 0;
}

.carousel-showmanymoveone .cloneditem-1,
.carousel-showmanymoveone .cloneditem-2,
.carousel-showmanymoveone .cloneditem-3 {
   display: none;
}

.carousel .item .col-xs-12 {
   padding: 0 10px;
}
*/


/* Medium Devices, Desktops */

@media only screen and (max-width: 992px) {
    .carousel .item .col-xs-12:nth-last-child(-n+1) {
        display: none;
    }
}

@media only screen and (max-width: 767px) {
    .carousel .item .col-xs-12:nth-last-child(-n+2) {
        display: none;
    }
}

@media all and (min-width: 768px) {
    .carousel-showmanymoveone .carousel-inner>.active.left,
    .carousel-showmanymoveone .carousel-inner>.prev {
        left: -50%;
    }
    .carousel-showmanymoveone .carousel-inner>.active.right,
    .carousel-showmanymoveone .carousel-inner>.next {
        left: 50%;
    }
    .carousel-showmanymoveone .carousel-inner>.left,
    .carousel-showmanymoveone .carousel-inner>.prev.right,
    .carousel-showmanymoveone .carousel-inner>.active {
        left: 0;
    }
    .carousel-showmanymoveone .carousel-inner .cloneditem-1 {
        display: block;
    }
}

@media all and (min-width: 768px) and (transform-3d),
all and (min-width: 768px) and (-webkit-transform-3d) {
    .carousel-showmanymoveone .carousel-inner>.item.active.right,
    .carousel-showmanymoveone .carousel-inner>.item.next {
        -webkit-transform: translate3d(50%, 0, 0);
        transform: translate3d(50%, 0, 0);
        left: 0;
    }
    .carousel-showmanymoveone .carousel-inner>.item.active.left,
    .carousel-showmanymoveone .carousel-inner>.item.prev {
        -webkit-transform: translate3d(-50%, 0, 0);
        transform: translate3d(-50%, 0, 0);
        left: 0;
    }
    .carousel-showmanymoveone .carousel-inner>.item.left,
    .carousel-showmanymoveone .carousel-inner>.item.prev.right,
    .carousel-showmanymoveone .carousel-inner>.item.active {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        left: 0;
    }
}

@media all and (min-width: 992px) {
    .carousel-showmanymoveone .carousel-inner>.active.left,
    .carousel-showmanymoveone .carousel-inner>.prev {
        left: -25%;
    }
    .carousel-showmanymoveone .carousel-inner>.active.right,
    .carousel-showmanymoveone .carousel-inner>.next {
        left: 25%;
    }
    .carousel-showmanymoveone .carousel-inner>.left,
    .carousel-showmanymoveone .carousel-inner>.prev.right,
    .carousel-showmanymoveone .carousel-inner>.active {
        left: 0;
    }
    .carousel-showmanymoveone .carousel-inner .cloneditem-2,
    .carousel-showmanymoveone .carousel-inner .cloneditem-3 {
        display: block;
    }
}

@media all and (min-width: 992px) and (transform-3d),
all and (min-width: 992px) and (-webkit-transform-3d) {
    .carousel-showmanymoveone .carousel-inner>.item.active.right,
    .carousel-showmanymoveone .carousel-inner>.item.next {
        -webkit-transform: translate3d(33%, 0, 0);
        transform: translate3d(33%, 0, 0);
        left: 0;
    }
    .carousel-showmanymoveone .carousel-inner>.item.active.left,
    .carousel-showmanymoveone .carousel-inner>.item.prev {
        -webkit-transform: translate3d(-33%, 0, 0);
        transform: translate3d(-33%, 0, 0);
        left: 0;
    }
    .carousel-showmanymoveone .carousel-inner>.item.left,
    .carousel-showmanymoveone .carousel-inner>.item.prev.right,
    .carousel-showmanymoveone .carousel-inner>.item.active {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        left: 0;
    }
}


/*media query by bhautik*/

@media only screen and (max-width: 1023px) {
    .afterlogin-header .logo-wrapper {
        /*width: auto;
        padding: 8px 10px;
        background: white;*/
        width: auto;
        padding: 0px 10px;
        background: #1c207a;
    }
    .tb-mg-logo {
        display: block;
    }
    .desktop-logo {
        display: none;
    }
    .afterlogin-header .logo-wrapper a img {
        width: 100%;
        max-width: 90px;
        height: 44px;
    }
    .message-notify-btn>a,
    .notification-btn>a {
        padding: 5px 20px !important;
    }
    .message-notify-btn>a>.badge,
    .notification-btn>a>.badge {
        top: 4px !important;
        right: 14px !important;
    }
}

@media (min-width: 768px) {
    .h4,
    h4 {
        line-height: 1.25;
        font-size: 18px;
    }
    .h6,
    h6 {
        line-height: 1.47059;
        font-size: 16px;
    }
    .h1,
    h1 {
        line-height: 1.2;
        font-size: 36px;
        font-weight: 100;
    }
}

@media only screen and (max-width: 767px) {
    h1 {
        font-size: 24px;
        margin-bottom: 15px;
        font-weight: 100 !important;
    }
}


/*end media query by bhautik*/