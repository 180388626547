@media only screen and (max-width:1300px){
	.technician-list{width: 100%;}
}

@media only screen and (max-width:1199px){
	.modal{margin-top:75px;}
	.select-to-date-wrapper{margin-top:0px;}
	.filter-wrapper{margin-top:30px;}
	.stepwizard-step a > span.circle::after{display:none;}

	.rd-navbar-panel{position:relative;}
	.rd-navbar-floated.rd-navbar-fixed .rd-navbar-brand{display:block !important;
		-webkit-transform:none;
		-moz-transform:none;
		-ms-transform:none;
		transform:none;

		position:absolute;width:127px;
		top:4px;left:0;right:0;margin:0 auto;
	}

	.rd-navbar-mobile-brand{display:none !important;}
	.afterlogin-header .rd-navbar-brand{position:static !important;width:100% !important;}

}

@media only screen and (max-width:1023px){
	.afterlogin-header .rd-navbar-toggle{display:block !important;}

	.afterlogin-header .rd-navbar-brand{width:127px !important;position:fixed !important;padding:0 !important;}
	.afterlogin-header .rd-navbar-brand > a{margin:0 !important;}

	.afterlogin-header .rd-navbar-nav-wrap{
		transform:translateX(-105%) !important;
		-webkit-transform:translateX(-105%) !important;
	}
	.afterlogin-header .rd-navbar-nav-wrap.active{
		transform:translateX(0px) !important;
		-webkit-transform:translateX(0px) !important;
	}
	.rightside-container,
	.inner-footer{padding-left:0;min-height:0 !important;}
	.inner-footer{margin-top:20px;}

	.afterlogin-header .logo-wrapper{width:auto;border:0;padding:0;}
	.afterlogin-header .logout-btn{padding:10px 0 10px 15px;}
	
	.rd-navbar-toggle{top:4px !important;}
	.afterlogin-header .rd-navbar-toggle{top:0px !important;}
	
	.rightside-container{padding-top:48px;}

	.top-username,
	.top-help,
	.top-timer-session{display:none !important;}

	.notification-btn > a,
	.message-notify-btn > a{padding:8px 20px;}

	.notification-btn > a > .badge,
	.message-notify-btn > a > .badge{top:4px;right:14px;}

	.notification-wrapper,
	.message-notify-list-wrapper{top:48px;}

	.stepwizard-step-wrapper .stepwizard-step{min-width:0;}
	#messages{margin:0;}

	.afterlogin-header .rd-navbar-nav-wrap{padding:48px 0 10px !important;}
}

@media only screen and (max-width:991px){
	.profile-pic-wrapper{margin-bottom:30px !important;}

	.tags-section-container{margin-top:0 !important;}
	.tags-section-wrapper{margin-bottom:15px !important;}

	.certified-section{margin-top:30px !important;}

	.resp-tabs-container-mobile{margin-top:0 !important;}

	.service-wrapper h4{font-size:20px !important;}
	.service-wrapper .offset-top-41{margin-top:20px !important;}

	.select-from-date-wrapper{margin-bottom:10px;}
	.select-date-wrapper{margin-bottom:30px;}

	.offset-sm-bottom-20{margin-bottom:20px;}
	.offset-sm-bottom-20:last-child{margin-bottom:0px !important;}

	.message-box-wrapper{border-top:1px solid #e3e7f3;}
}

@media only screen and (max-width:767px){
	.section-wrapper{padding-left:10px;padding-right:10px;}

	.profile-form-section > .row{margin-top:0 !important;}
	.profile-form-section > .row > .col-xs-12{margin-bottom:30px;padding-bottom:0 !important;}
	.profile-form-section > .row > .col-xs-12 .form-group{margin-bottom:0 !important;}

	/*.profile-container{padding-bottom:0;}*/

	.technician_preview_img{margin:0 auto;}

	.technician_profile-left-details,
	.technician_profile-right-details{text-align:center !important;}

	.technician_profile-left-details{margin-bottom:30px !important;}

	.tags-section-wrapper{text-align:left !important;}

	.technician-profile-left-details-wrapper{text-align:center;}
	.technician-profile-pic-wrapper,
	.technician_profile-left-details{display:inline-block;vertical-align:top;text-align:left !important;}

	.technician_preview_img{width:150px;margin-right:20px;margin-bottom:0px !important;}
	.technician-profile-pic-wrapper{margin-bottom:0px !important;}

	.create_work_order_fields_wrapper > .col-xs-12{margin-bottom:30px !important;}

	.service-wrapper{margin-bottom:30px;}

	.search-work-order-wrapper{display:block;}
	.search-work-order-wrapper .left-column,
	.search-work-order-wrapper .right-column{width:100%;padding:0;}

	.disableRadio .radio{display:inline-block;vertical-align:top;margin:0 15px 15px 0;}
	.disableRadio .radio label{margin:0;}

	.technician-content-section{margin:0 0 30px !important;}
	.technician-profile-text{margin:0 !important;}

	.notification-section,
	.message-notify-wrapper{display:none;}

	.edit-profile-btn{position:static;margin-top:10px;}

	.order-section{text-align:center;}
	.order-section-right,
	.order-section > h4,
	.order-section-right > label{float:none !important;}

	.order-section-right{margin-top:10px;}
	.order-section-right > label{display:inline-block;margin-top:10px;margin-left:2px;}

	.add-edit-user-wrapper{margin-top:0 !important;}
	.add-edit-user-wrapper > .col-xs-12{margin-bottom:20px;}

	.status-section{margin-top:30px !important;}

	.message-listing-left{display:none;}
	.message-listing-right{width:100%;float:none;border-right:1px solid #e3e7f3;}

	.write-message-section .attached-area{position:static;border-top:1px solid #e3e7f3;margin:0;}
	.write-message-section{padding:20px;}

	h1{font-size:24px;margin-bottom:15px;line-height:normal;}
	.divider{margin:10px auto;}

	h4{font-size:20px;margin-bottom:15px;line-height:normal;}
	.text-subline{margin:10px 0 !important;}

	.profile-container, 
	.workorder-container{box-shadow:none !important;}

	.icon-xs{font-size:24px;}
	.order-content-footer .mdi{margin-right:5px;}
	.order-content-footer .list-inline-sm li{font-size:14px;}

	.offset-top-30{margin-top:15px;}
}

@media only screen and (max-width:640px){
	.user-head{display:none;}
}

@media only screen and (max-width:600px){
	.technician-profile-pic-wrapper, 
	.technician_profile-left-details{display:block !important;}

	.technician_preview_img{margin:0px auto !important;}

	.order-content-footer .list-inline-sm li{padding-top:10px;padding-bottom:10px;}
}

@media only screen and (max-width:479px){
	.afterlogin-header .rd-navbar-brand{display:block !important;position:fixed !important;width:140px;margin:0 auto !important;top:4px !important;left:0;right:0;
		transform:none !important;
		-webkit-transform:none !important;
	}
	.afterlogin-header .rd-navbar-brand > a{margin:0 !important;}
	.logout-label{display:none;}

	.technician-photo-section{position:static;text-align:center;width:auto;height:auto;}
	.technician-data-section{padding:15px;text-align:center;}
	.technician-datalist-section li{display:block;padding:0 0 10px 0;}
	.technician-btn-section{position:static;text-align:center;}
}