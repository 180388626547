

/*fieldset, label { margin: 0; padding: 0; }*/
/*body{ margin: 20px; }*/
/*h1 { font-size: 1.5em; margin: 10px; }*/

/****** Style Star Rating Widget *****/

.rating {
    border: none;
    float: left;
}

.rating > input { display: none; }
.rating > label:before {
    margin: 5px;
    font-size: 2.50em;
    font-family: FontAwesome;
    display: inline-block;
    content: "\f005";
}

.rating > .half:before {
    content: "\f089";
    position: absolute;
}

.rating > label {
    color: #ddd;
    float: right;
}

/***** CSS Magic to Highlight Stars on Hover *****/

.rating > input:checked ~ label, /* show gold star when clicked */
.rating:not(:checked) > label:hover, /* hover current star */
.rating:not(:checked) > label:hover ~ label { color: #FFD700;  } /* hover previous stars in list */

.rating > input:checked + label:hover, /* hover current star when changing rating */
.rating > input:checked ~ label:hover,
.rating > label:hover ~ input:checked ~ label, /* lighten current selection */
.rating > input:checked ~ label:hover ~ label { color: #FFED85;  }

/* Static Rating Css */

.star-ratings-sprite {
    background: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/2605/star-rating-sprite.png") repeat-x;
    font-size: 0;
    height: 15px;
    line-height: 0;
    overflow: hidden;
    text-indent: -999em;
    width: 81px;
   /* margin: 10px auto;*/
   background-size: 16px;
}
.technician-datalist-section li h5{margin:10px 0 !important;}
.star-ratings-sprite-rating {
    background: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/2605/star-rating-sprite.png") repeat-x;
    background-position: 0 100%;
    float: left;
    height: 15px;
    display: block;
    background-size: 16px;
}

.ratingWrap { margin: 10px 5px; }
.ratingWrap .ratingNumber {    margin-top: 20px;
    background: #f1f1f1;
    height: 36px;
    width: 175px;}

.ratingRat{width: 180px;}
.ratingRat .left{width: 130px;}
.error2{    word-wrap: break-word;
    white-space: normal;
}
/********* Middle *********/
    .middle{display: flex;
    justify-content: center; /* align horizontal */
    align-items: center; /* align vertical */}

    .middle_left{display: flex; 
    align-items: center; /* align vertical */}