html{height:100% !important;}
body{height:100% !important;}

body,
.page{background-color:#fff !important;}

h1{text-transform:capitalize;}

.margin-0{margin:0 !important;}
.offset-top-25{margin-top:25px !important;}

.rightside-container{padding-left:280px;padding-top:64px;}
.inner-footer{padding-left:280px;padding-top:10px;padding-bottom:10px;background:#323a44;}

.section-wrapper{padding-left:15px;padding-right:15px;}

.shadow{box-shadow:0 0 10px rgba(0,0,0,0.10) !important;}

/* Header Navigation */

.rd-navbar-wrap{height:auto !important;position:fixed;width:100%;z-index:999;}
.rd-navbar-floated.rd-navbar-static{margin-top:0px !important;background:#1d2025 !important;}
.rd-navbar-floated.rd-navbar-dark .rd-navbar-inner{box-shadow:none !important;background:#1d2025 !important;padding:10px 40px;}

.rd-navbar-brand,
.rd-navbar-panel{background:#1d2025 !important;}

.rd-navbar-brand a{display:block;}

.rd-navbar-floated.rd-navbar-static .rd-navbar-nav-wrap{margin-top:6px;}


.modal{margin-top:60px;}
.display-inline{display:inline !important;}
body{padding:0 !important;}

.profile-container,
.workorder-container{ box-shadow: 0 0 15px rgba(0,0,0,0.10) !important;border: 1px solid #ececec;/*border-radius:5px;*/padding-top:30px;padding-bottom:30px;background:#f7f7f7;}

.preview_img{min-width:200px;min-height:200px;margin:0 auto;border:1px solid #d9d9d9;padding:5px;}
.preview_img > a{display:block;}
.preview_img img{max-width:100%;}

.profile-container input,
.profile-container textarea,
.profile-container .select2-container,
.profile-container-view .form-control-impressed,
.profile-container .radio-custom-dummy,
.profile-container .checkbox-custom-dummy{background:#fff;}

.workorder-container input,
.workorder-container textarea,
.workorder-container .select2-container,
.workorder-container .form-control-impressed,
.workorder-container .radio-custom-dummy,
.workorder-container .checkbox-custom-dummy{background:#fff;}

.tags-section-wrapper h6 > p{margin-bottom:5px;}
.tags-section-wrapper h6 > span{text-transform:uppercase;}

.certificate-section > div{width:100px;float:left;margin-right:10px;}
.certificate-section > div:last-child{margin-right:0;}

.certified-section > p{margin-right:15px;margin-bottom:15px;margin-top:0;}
.certified-section > p:last-child{margin-right:0;}
.certified-section > p a{display:block;}

.afterlogin-header .rd-navbar-toggle{display:none !important;}

.afterlogin-header .rd-navbar-nav-wrap{padding:71px 0 10px !important;background:#323a44 !important;
	transform:translateX(0px) !important;
	-webkit-transform:translateX(0px) !important;
}

.afterlogin-header .rd-navbar-panel{box-shadow:none !important;}

.afterlogin-header .rd-navbar-brand{position:static !important;transform:none !important;}
.afterlogin-header .rd-navbar-brand > a{display:inline-block !important;margin-left:15px;}
.afterlogin-header .rd-navbar-brand > a > img{margin:0 !important;}

.afterlogin-header .rd-navbar-wrap,
.afterlogin-header .rd-navbar-dark,
.afterlogin-header .rd-navbar-panel{height:auto !important;}

.afterlogin-header .rd-navbar-nav{margin-top:0 !important;}
.afterlogin-header .rd-navbar-nav > li.user-section{padding:0 15px;}

.afterlogin-header .rd-navbar-nav > li > a,
.afterlogin-header .rd-navbar-nav > li > ul > li > a{text-transform:capitalize !important;letter-spacing:0.5px !important;font-weight:normal !important;padding-top:10px !important;padding-bottom:10px !important;}
.afterlogin-header .rd-navbar-submenu-toggle::before{line-height:65px !important;}

.afterlogin-header .rd-navbar-nav > li > a{font-size:16px !important;}
.afterlogin-header .rd-navbar-nav > li > ul > li > a{font-size:14px !important;}

.username-view{display:inline-block;margin:5px 0 0 0 !important;background:#191919 !important;}

.user-image{float:left;width:60px;}
.user-image img{max-width:100%;}
.username{float:left;width:180px; vertical-align:top;text-align:left;margin:0 0 0 10px;letter-spacing:0.5px;text-transform:capitalize;}

.stepwizard-step-wrapper{text-align:center;}
.stepwizard-step-wrapper .stepwizard-step{min-width:100px;display:inline-block;vertical-align:middle;margin:10px 5px;}
.btn-small{font-size:20px;padding:10px;border-radius:50%;background:#434345;color:#fff;border:0;min-width:50px;min-height:50px;}
.btn-small.btn-primary{background:#42b574;}

.afterlogin-header .top-right-header{position:fixed;top:0;right:15px;z-index:16;color:#fff;}

.afterlogin-header .logout-btn{padding:18px 0 18px 15px;/*border-left:1px solid #343940;box-shadow:-1px 0 0 #121418;*/}
.afterlogin-header .logout-btn a{display:block;font-size:18px;letter-spacing:1px;}
.afterlogin-header .logout-btn a:hover,
.afterlogin-header .logout-btn a:focus{color:#ff6600;}

.afterlogin-header .logged-user-details ul{padding:0;}
.afterlogin-header .logged-user-details ul li{margin:0 12px;display:inline-block;vertical-align:middle;}
.afterlogin-header .logged-user-details ul li span.text-dark {color:#a8b5c8;}
.afterlogin-header .logged-user-details ul li span.timer{display:block;margin-left:32px;}

.afterlogin-header .top-username,
.afterlogin-header .top-help{line-height:64px;}
.afterlogin-header .top-timer-session{line-height:10px;}

.afterlogin-header .top-username{text-transform:capitalize;padding:0 20px;border-left: 1px solid #343940;box-shadow: -1px 0 0 #121418;}

.afterlogin-header .logo-wrapper{display:block;width:280px;/*border-right:1px solid #343940;box-shadow:1px 0 0 #121418;*/padding:11px 10px;text-align:center;}
.afterlogin-header .logo-wrapper a{display:inline-block;}

.section-divider{padding:20px 15px;border-bottom:1px solid #2c343d;box-shadow:0 1px 0 #444b55;}
.section-divider h6{letter-spacing:1px;}

.afterlogin-header ul.user-section{margin-top:0 !important;padding:20px 15px !important;margin-bottom:1px !important;}
.afterlogin-header ul.user-section > li{margin-bottom:0px !important;border-bottom:0 !important;box-shadow:none !important;}
.afterlogin-header .bottom-divider{border-bottom:1px solid #2c343d;box-shadow:0 1px 0 #444b55;}

.afterlogin-header ul.rd-navbar-nav li{border-bottom:1px solid #444b55;margin:0 !important;}
.afterlogin-header ul.rd-navbar-nav li.rd-navbar-submenu{margin-top:0 !important;margin-bottom:0 !important;}
.afterlogin-header ul.rd-navbar-nav li a{padding-top:15px !important;padding-bottom:15px !important;line-height:32px !important;}

.afterlogin-header li.rd-navbar-submenu a,
.afterlogin-header .rd-navbar-nav a:hover{background:#212832 !important;}

.afterlogin-header ul.rd-navbar-dropdown a{background:#3d4248 !important;}

.afterlogin-header .rd-navbar-dropdown{padding:0 !important;border-top:1px solid #444b55;}
.afterlogin-header .rd-navbar-dropdown li{margin:0 !important;border-bottom:1px solid #262e39;}
.afterlogin-header .rd-navbar-dropdown li:last-child{border-bottom:0 !important;}


.afterlogin-header .rd-navbar-dropdown li a{border-left:5px solid #3d4248;}

.afterlogin-header .rd-navbar-dropdown li:hover a,
.afterlogin-header .rd-navbar-dropdown li.active a{border-color:#ff7900 !important;}


.afterlogin-header .rd-navbar-mobile-scroll{height:100%;}

.afterlogin-header .rd-navbar-submenu-toggle{position:absolute;width:100% !important;text-align:right;left:0;padding:0 15px;}

.white-box{/*width:98%;background:#fff;*/margin-left:auto !important;margin-right:auto !important;float:none;/*padding-top:20px;padding-bottom:20px;*/}
.left-column .white-box{border:1px solid #e3e7f3;padding:15px 0;}

.white-box label{font-size:16px;color:#1d2025;margin-bottom:20px;letter-spacing:0.5px;}
.white-box .form-control,
.white-box .select2-selection{font-size:14px;color:#afafaf;letter-spacing:0.5px;border:0;/*box-shadow:0 0 30px rgba(0,0,0,0.14);*/border:1px solid #dadada;border-radius:0;}

.select2-dropdown{border:1px solid #d9d9d9 !important;}

.row.work-order-lists{margin-left:0;margin-right:0;}
.work-order-lists-wrapper{/*width:98%;*/margin-left:auto;margin-right:auto;float:none;/*padding:0;*/}
.work-order-technician-lists-wrapper{padding:0;}
.work-order-lists-wrapper > .row > .col-xs-12{padding:0;margin:0 0 30px;}
.work-order-lists > .col-xs-12{margin-bottom:20px;background:#fff;padding:0px;border:1px solid #e3e7f3;}
.work-order-lists > .col-xs-12:last-child{margin-bottom:0;}

.order-section{padding:20px 15px;border-bottom:1px solid #e3e7f3;}
.order-section h4{font-size:24px;letter-spacing:0.5px;margin:5px 0 0 0;}


.order-section-right > label{margin-left:10px;margin-bottom:0px;}
.order-section-right > label.label-custom-wrapper{margin-top:5px;}
.order-section-right > label:hover .mdi{background-color:#42b574;color:#fff;}

.order-id{color:#a8b5c8;}

.order-content{letter-spacing:0.5px;font-size:16px;color:#333;text-transform:capitalize;}
.order-content .order-content-label{color:#a8b5c8;}

.order-content-footer{padding:0 15px;border-top:1px solid #e3e7f3;}
.order-content-footer ul{margin:0;}
.order-content-footer .mdi{color:#9a9a9a;margin-right:10px;}

.order-content-footer .list-inline-sm li{padding-top:20px;padding-bottom:20px;padding-left:0px;padding-right:40px;font-size:16px;}
.order-content-footer .list-inline-sm li .text-dark{color:#333;}

.technology-section-content:hover .mdi{background-color:#42b574;color:#fff;}

/* Overlay and loader css : tausif */
#overlay {position: fixed; top:0px; left:0px; right:0px; bottom:0px; background-color: black; opacity: 0.6; z-index: 50000; display: none}
#loading { position: absolute; top: 50%; left: 50%; margin: -28px 0 0 -25px; }

/*.select-to-date-wrapper{margin-top:10px;}*/

/* Header Notification */
.notification-section{position:relative;margin:0 10px 0 0;}
.notification-btn{position:relative;}

.notification-btn > a{display:block;padding:15px 20px;}
.notification-btn > a > .badge{position:absolute;top:10px;right:15px;padding:2px 6px;font-size:16px;border:1px solid #1d2025;}

.notification-btn > a:hover,
.notification-btn > a.active{background:#3d4248;}

.notification-wrapper{width:280px;position:absolute;top:64px;right:0;background:#3d4248;opacity:0;display:none;
	transition: .3s all ease;
}
.notification-wrapper.active{opacity:1;z-index:1;display:block;
	transition: .3s all ease;
}

.notification-wrapper ul{padding:0;max-height:250px;overflow-y:auto;}
.notification-wrapper ul li{padding:10px;border-bottom:1px solid #444b55;}
.notification-wrapper ul li:last-child{border-bottom:none !important;}

.notification-wrapper ul li a{display:block;}

.notification-wrapper ul li.unread,
.notification-wrapper ul li:hover{background:#1d2025;transition: .3s all ease;}

.user-details,
.user-details span{display:block;}

.user-details .user-photo{width:60px;}

.user-content{width:180px;letter-spacing:0.5px;text-transform:capitalize;}
.user-content .user-name{font-size:14px;}
.user-content .user-text{font-size:13px;color:#ddd;}

.time-date-stamp{font-size:12px;color:#fff;text-transform:uppercase;letter-spacing:0.5px;display:block;margin:10px 0 0 0 !important}
.time-date-stamp span{display:inline !important;}

.notification-dropdown-btn{padding:10px !important;border-top:1px solid #444b55;margin:0 !important;
	-webkit-transform: translateY(0px) !important;
	transform: translateY(0px) !important;
}
.notification-dropdown-btn > .btn{margin-top:0 !important;}

/* Work Order View */
.page-title{font-size:24px;font-weight:bold;color:#212832;text-align:center;letter-spacing:0.5px;text-transform:capitalize;}
.stepwizard-step p{font-size:14px;font-weight:bold;color:#1d2025;text-transform:capitalize;letter-spacing:0.5px;}

.stepwizard-step a{display:inline-block;pointer-events:none;}
.stepwizard-step a > span.circle{display:block;margin:0 auto;width:20px;height:20px;border:2px solid #d6d6d6;border-radius:50%;background:#fff;position:relative;z-index:2;} 
.stepwizard-step a > span.circle span{display:block;background:#d6d6d6;width:100%;height:100%;border-radius:50%;border:3px solid #fff;}
.stepwizard-step a > span.circle::after{display:block;content:"";width:112px;height:2px;background:#a2a2a2;position:absolute;top:7px;left:18px;z-index:-1;}
.stepwizard-step:last-child a > span.circle::after{display:none;}

.stepwizard-step a.status-completed > span.circle{border-color:#42b574;}
.stepwizard-step a.status-completed > span.circle span{background:#42b574;}

.responsive-tabs ul.workorder-tabs li{color:#1d2025;text-transform:capitalize;letter-spacing:0.5px;}
.responsive-tabs ul.workorder-tabs li:before{height:4px !important;background-color:#42b574 !important;}

.workorder-tabs-content-wrapper{/*background:#fff;*/border-top:1px solid #b8babf;/*padding:0 20px;*/}

.text-subline{background:#e3e7f3;margin:24px 0px !important;}
.text-subline:before{height:4px;top:-3px;background:#42b574;}

.work-order-list-details{padding:0;}
.work-order-list-details li{display:block;margin:0 0 10px;}
.work-order-view-label{font-size:16px;color:#515151;text-transform:capitalize;}
.work-order-view-text{font-size:16px;font-weight:bold;color:#191919;text-transform:capitalize;}
.work-order-username{letter-spacing:0.5px;}
.word-order-view-map{width:100%;height:250px;}

/* Messages */
#messages{padding:0px;/*margin:0 -20px;*/}

.message-listing-left{float:left;width:25%;}
.message-listing-right{float:left;width:75%;border-left:1px solid #e3e7f3;}

.message-left-head{padding:40px 25px;/*border-bottom:1px solid #e3e7f3;*/}
.message-right-head{padding:20px 25px;border-bottom:1px solid #e3e7f3;}

.search-in-list{position:relative;}
.search-in-list span.icon{position:absolute;top:8px;left:0;}
.search-in-list input{border:0;font-size:18px;color:#212832;letter-spacing:0.5px;width:100%;padding:5px 5px 5px 30px;outline:0;}

::placeholder{color:#212832;}
::-webkit-input-placeholder{color:#212832;}

.message-left-body ul{padding:0;max-height:400px;overflow-y:auto;overflow-x:hidden;}

.message-left-body ul li{display:block;list-style:none;padding:22px 20px 21px 60px;position:relative;border-bottom: 1px solid #e3e7f3;}

.message-left-body ul li span{display:block}
.message-left-body ul li .user-photo{width:50px;position:absolute;left:10px;top:20px;}
.message-left-body ul li .user-content{width:100%;float:left;padding-left:10px;}
.message-left-body ul li .time-stamp{width:80px;float:left;text-align:right;}

.user-content .name{font-size:14px;font-weight:bold;color:#000;letter-spacing:0.5px;margin-top:5px;margin-bottom:0px;}
.user-content .message-short-text{font-size:14px;color:#676767;letter-spacing:0.5px;margin:0;}

.user-content .name,
.user-content .message-short-text{white-space:nowrap;overflow:hidden;text-overflow:ellipsis;}

.time-stamp .message-time{font-size:14px;color:#9f9f9f;margin-bottom:5px;letter-spacing:0.5px;}
.time-stamp .badge{display:inline-block;font-size:14px;background:#f54b0f;}

.current-user .current-user-photo{width:60px;float:left;}
.current-user .current-user-content{float:left;}

.current-user-content .current-user-name{font-size:14px;font-weight:bold;color:#000;letter-spacing:0.5px;margin-top:5px;margin-bottom:0px;}
.current-user-content .current-user-time-location{font-size:14px;color:#9f9f9f;margin-bottom:5px;letter-spacing:0.5px;margin:0;}

.message-right-body{min-height:400px;max-height:400px;overflow-y:auto;padding:20px 30px;}
.write-message-section{margin:30px 0 0;padding:30px 170px 30px 30px;border-top:1px solid #e3e7f3;border-bottom: 1px solid #e3e7f3;position:relative;background:#fff;}

.write-message-section .write-area{width:100%;}
.write-message-section .attached-area{position:absolute;right:30px;top:30px;}
.write-message-section .attached-area a{margin-top:0 !important;}

.write-area textarea{width:100%;max-width:100%;max-height:350px;overflow-y:auto;resize:none;font-size:18px;color:#1f1f1f;border:0;outline:0;}
.conversation{text-align:center;font-size:14px;font-weight:bold;color:#000;text-transform:capitalize;margin:15px 0 !important;}

.received,
.sent{letter-spacing:0.5px;position:relative;}

.received p{background:#f7f7f7;padding:15px 20px;margin:0 0 10px;border-radius:10px;color:#000;}
.sent p{background:#556080;padding:15px 20px;margin:0 0 10px;border-radius:10px;color:#fff;}


.received-anchor{display:block;width:25px;height:25px;background-repeat:no-repeat;background-position:top center;background-size:cover;background-image:url('../images/receiver-anchor.png');
	position:absolute;top:0;left:-10px;
}
.sent-anchor{display:block;width:25px;height:25px;background-repeat:no-repeat;background-position:top center;background-size:cover;background-image:url('../images/sent-anchor.png');
	position:absolute;top:0;right:-10px;
}

.converstation-time-stamp{font-size:18px;color:#a8a8a8;letter-spacing:0.5px;text-align:right;margin:5px 0 30px 0;}


/* WorkSpace */
.check-view{font-size:14px;color:#000;letter-spacing:0.5px;}

/* Payment */
.hours-section{letter-spacing:0.5px;}
.hours-section .big-text{font-size:50px;}
.hours-section .small-text{font-size:16px;text-transform:capitalize;}
.hours-section p{margin:0 !important;}

/* Search Work Order */
.search-work-order-wrapper{display:flex;}
.search-work-order-wrapper .left-column{width:300px;padding-left:15px;}
.search-work-order-wrapper .right-column{width:100%;padding:0 15px;}

.disableRadio .radio:last-child label{margin-bottom:0;}


/* Technician View */
.technician-profile-view{padding-top:30px;padding-bottom:30px;background-size:cover;background-repeat:no-repeat;background-image:url('../images/user-bg.jpg');background-position:center;position:relative;}

.edit-profile-btn{position:absolute;right:10px;bottom:10px;}

.photo-wrapper img{margin:0 auto;border:5px solid rgba(71,117,162,25);}
.technician-name{font-size:40px;color:#fff;letter-spacing:0.5px;margin:0px;}
.photo-wrapper .user-type{margin:0;font-size:20px;color:#fff;letter-spacing:0.5px;text-transform:capitalize;}

.technician-profile-view-wrapper{padding:0 15px;}
.technician-profile-view-wrapper .text-subline{background:#e3e7f3;margin:24px 0px !important;}
.technician-profile-view-wrapper .text-subline:before{height:4px;top:-3px;background:#42b574;}

.technician-profile-label{margin:0 0 5px 0 !important;color:#000;text-transform:capitalize;letter-spacing:0.5px;}
.technician-profile-text{margin:0 !important;color:#808080;}


/* Header Message Listing */
.message-notify-wrapper{margin-left:15px;position:relative;}
.message-notify-btn{position:relative;}

.message-notify-btn > a{display:block;padding:15px 20px;}
.message-notify-btn > a > .badge{position:absolute;top:10px;right:15px;padding:2px 6px;font-size:16px;border:1px solid #1d2025;}

.message-notify-btn > a:hover,
.message-notify-btn > a.active{background:#3d4248;}

.message-notify-list-wrapper{width:280px;position:absolute;top:64px;right:0;background:#3d4248;opacity:0;display:none;
	transition: .3s all ease;
}
.message-notify-list-wrapper.active{opacity:1;z-index:1;display:block;
	transition: .3s all ease;
}

.message-notify-list-wrapper ul{padding:0;max-height:250px;overflow-y:auto;}
.message-notify-list-wrapper ul li{padding:10px;border-bottom:1px solid #444b55;}
.message-notify-list-wrapper ul li:last-child{border-bottom:none !important;}

.message-notify-list-wrapper ul li a{display:block;}

.message-notify-list-wrapper ul li.unread,
.message-notify-list-wrapper ul li:hover{background:#1d2025;transition: .3s all ease;}

.user-details,
.user-details span{display:block;}

.user-details .user-photo{width:60px;}

.user-content{width:180px;letter-spacing:0.5px;text-transform:capitalize;}
.user-content .user-name{font-size:14px;}
.user-content .user-text{font-size:13px;color:#ddd;}

.time-date-stamp{font-size:12px;color:#fff;text-transform:uppercase;letter-spacing:0.5px;display:block;margin:10px 0 0 0 !important}
.time-date-stamp span{display:inline !important;}

.notification-dropdown-btn{padding:10px !important;border-top:1px solid #444b55;margin:0 !important;
	-webkit-transform: translateY(0px) !important;
	transform: translateY(0px) !important;
}
.notification-dropdown-btn > .btn{margin-top:0 !important;}

.ui-to-top{z-index:99;}

.button-group > label{margin-right:5px;margin-bottom:0 !important;display:inline-block;}
.button-group > label:last-child{margin-right:0;}

#attach-file{position:absolute;top:0;left:0;z-index:-9999;opacity:0;}

.notify-loader{width: 30px;margin: 20px auto;display: block;}

.icon-xxs.icon-rect, 
.icon-xxs.icon-rounded, 
.icon-xxs.icon-circle, 
.icon-xxs.icon-outlined{font-size:20px;}

.icon-rect, 
.icon-circle, 
.icon-rounded, 
.icon-outlined{border:1px solid;}

.datatable-btn label:hover .mdi{background-color:#42b574;color:#fff;}

#map_wrapper{height: 400px;border:1px solid #e3e7f3;}
#map_canvas{width:100%;height:100%;}
.pagination{margin:0;}

.btn{letter-spacing:1px;}

#userData.list-group-item{padding:0;border:0;}
.list-group-item > label{cursor:pointer;padding:0 5px;}
.list-group-item > label > input[type="radio"]{margin:0 5px 0 0;}

h1,
h4{color:#fa7700;}


.panel{position:relative;}
.close-login{position:absolute;top:10px;right:10px;}

.white-text{color:#fff;}

.mce-panel.mce-last{display:none !important;}

.errmsg{color:red;}

.we-can-do-content{letter-spacing:0.5px;}

.mce-edit-area iframe > html,
.mce-edit-area iframe > html > body,
.mce-content-body > p{height:100% !important;}

#technology .icon-circle{border:2px solid;}

.footer-powered-by{margin-top:30px;color:#fff;}
.footer-powered-by p{margin:0 0 5px !important;}
.footer-powered-by p:last-child{margin:0 !important;}

.technician-list{margin:0 20px 30px 0; float: left;width: 47%;background:#fff;border: 1px solid #e3e7f3; min-height: 132px;}
.technician-list:first-child{margin-right: 0px !important;}



.technician-list-header{padding:22px  0 0 28px;position:relative;}
.technician-photo-section{position:absolute;top:15px;left:15px;width:100px;height:100px;overflow:hidden;}
.technician-data-section{padding:5px 15px 15px 115px;}
.technician-list-footer{border-top:1px solid #e3e7f3;padding:15px;}
.technician-list-footer > button,
.technician-list-footer > a{margin:5px 10px;}

.technician-datalist-section li{padding-left:0px;padding-right:40px;font-size:16px;width: 100%;}
.technician-datalist-section li .mdi{color: #9a9a9a;margin-right: 10px;}
.technician-datalist-section li .text-dark{color:#333;}
.technician-datalist-section li .right-column{padding: 0px !important;}
/*.profile-container .radio-custom-dummy{display: none;}*/

.technician-btn-section{position:absolute;top:15px;right:15px;}
.technician-btn-section > label{margin-left:10px;}
.technician-btn-section > label:hover .mdi{background-color:#42b574;color:#fff;}



.shadow{box-shadow: 0 0 10px rgba(0,0,0,0.20) !important;}
.no_margin{margin:0px!important;}
.no_padding{padding:0px!important;}
.white-title{background: #ececec; padding: 10px 0; margin-bottom: -24px; }
/*.profile_bg{background: #212832;}*/
/*.profile_bg li .user-image{float: none; width: 60px; margin:0 auto;	}
.profile_bg li .username{float: none; width: 100%; margin:0 auto;text-align:center};*/

.ratings .radio{ padding: 10px 30px 0 15px;}
.ratings .radio label{font-size: 18px; color: #1d2025; margin:0;padding:0px; letter-spacing: 0.5px;}
.ratings .radio label.title{font-size: 16px;}
.ratings .radio label .iradio_square-green{z-index: 2;}

.ticket_view{background: #eee; margin: 20px; border-radius:20px;}
.ticket_view_detail{background: #fefefe;border-radius: 20px;margin:0 20px; padding:20px;}
.ticket_view_detail>div{height:30px;}
.ticket_view_detail>div>div h6{font-size: 16px;}
.ticket_view .page-title{color: #fa7700; font-size: 18px;}

.ticket_view_ud{background: #eee; margin: 10px; border-radius:20px;}
.ticket_view_detail_ud{background: #fefefe; border-radius: 20px; margin: 0px; padding: 20px;} 


.NewData div p{padding: 10px !important; border-bottom: 1px solid #ececec; margin: 0px;}
.NewData div p div{margin-right:10px;}
.NewData div h4{border-bottom: 4px double #ececec;padding: 10px;}
/*.rightside-container .section-20.bg-lightest{margin-top: 39px !important}*/

.modal-footer{margin-top:20px;}
.left{float: left;}
.right{float: right;}
.username a:hover{background-color: #fff !important;}